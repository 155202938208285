html,
body {
  height: 100%;
}

body {
  margin: 0;

  background: var(--background);
}

.form-box {
  margin-bottom: 50px;
  padding: 20px 35px 25px 25px;

  background: #fff;

  section {
    padding: 20px 0;
  }

  @media (max-width: 1400px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.input-columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 175px;

  .input-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @media (max-width: 1400px) {
    gap: 75px;
  }
}

.form-action-row {
  display: flex;
  padding-top: 40px;

  background: #fff;

  .save-btn {
    margin-left: auto;
  }
}

.details-header {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
}

.header-row {
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin: 0;
  }
}

.back-link {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;

  transition: gap 0.25s;

  &:hover {
    gap: 10px;

    cursor: pointer;
  }
}

.button-row {
  display: flex;
  gap: 20px;
}
